import React from "react";
import { Link } from "react-router-dom";

import SpinningLogo from "components/CustomWidgets/SpinningLogo";
import SocialMediaIcons from "components/CustomWidgets/SocialMediaIcons";
import { getSearchResultsOrderedById } from "components/common/CommonFunctions.jsx";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.setState({
      data: getSearchResultsOrderedById().slice(0, 5)
    });
  }

  render() {
    const { data } = this.state;
    return (
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="widget">
                <h5 className="widgetheading">
                  <SpinningLogo />
                  &nbsp;&nbsp;CONTACT
                </h5>
                <p>
                  <a href="mailto:contactus@learninjava.com">
                    contactus@learninjava.com
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="widget">
                <h5 className="widgetheading">CATEGORIES</h5>
                <ul className="link-list">
                  <li>
                    <Link to={"/artificial-intelligence/"}>
                      Artificial Intelligence
                    </Link>
                  </li>
                  <li>
                    <Link to={"/java-programming/"}>Java</Link>
                  </li>
                  <li>
                    <Link to={"/webservices/"}>Web Services</Link>
                  </li>
                  <li>
                    <Link to={"/spring/"}>Spring and Spring Boot</Link>
                  </li>
                  <li>
                    <Link to={"/javascript/"}>JavaScript</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="widget">
                <h5 className="widgetheading">LATEST POSTS</h5>
                <ul className="link-list">
                  {data &&
                    data.map((content, key) => (
                      <React.Fragment key={key}>
                        <li>
                          <Link to={content.url}>{content.title}</Link>
                        </li>
                      </React.Fragment>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="widget">
                <h5 className="widgetheading">ABOUT US</h5>
                <ul className="link-list">
                  <li>
                    <Link to={"/contact"}>About learninjava</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="sub-footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="copyright">
                  <p>
                    <strong>&copy;</strong> www.learninjava.com. All right
                    reserved. All trademarks and logos belongs to their owners.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <SocialMediaIcons color="white" size={18} />
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
